import { useMemo } from 'react'
import links, { getLinkWithParams } from 'links'
import { useFt } from 'hooks'
import { constants } from 'helpers'
import { getAnalyticsContext, trackOnce } from 'analytics'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useStrapiWSTONavigationData } from 'modules/brands'
import { useLimitedDropNavigationItems } from 'modules/limitedDrops'
import { messages as globalMessages } from 'modules/messages'
import { usePrivateSale } from 'modules/products'
import { useCandleSubscription, useCaseSubscription, useDriftSubscription, useSamplesSubscription, useSubscription } from 'modules/subscription'
import { useReferralInvites, useUser } from 'modules/user'
import { useSelectCampaigns } from 'modules/selectCampaigns'

import { ExclusiveLabel } from 'components/dataDisplay'

import { navMessages as messages } from 'compositions/navigation'
import ReferralNavItem from 'compositions/Header/components/ReferralNavItem/ReferralNavItem'
import { StrapiNavigationBanner, StrapiWSTONavigationBanner } from 'compositions/banners/strapi'


import {
  BuyQueueBanner,
  CandleSubscriptionBanner,
  CaseSubscriptionBanner,
  DriftSubscriptionNavBanner,
  SamplesSubscriptionBanner,
} from '../components/banners'


type GetGiftHubNavigationItemDataParams = {
  isHolidayMothersDayEnabled: boolean
  isHolidayFathersDayEnabled: boolean
  isGiftHubEvergreenEnabled: boolean
}

const getGiftHubNavigationItemData = (params: GetGiftHubNavigationItemDataParams) => {
  const { isHolidayMothersDayEnabled, isHolidayFathersDayEnabled, isGiftHubEvergreenEnabled } = params

  if (isHolidayMothersDayEnabled) {
    return {
      background: 'dark-pink' as const,
      exclusiveLabelVariant: 'dark-pink' as const,
      title: messages.gifts,
    }
  }

  if (isHolidayFathersDayEnabled) {
    return {
      background: 'gold-50' as const,
      exclusiveLabelVariant: 'gold-50' as const,
      title: messages.holidayGiftsFathersDay,
    }
  }

  return {
    background: 'vinous' as const,
    exclusiveLabelVariant: 'gold' as const,
    title: isGiftHubEvergreenEnabled ? messages.giftsEvergreen : messages.gifts,
  }
}

const useDesktopNav = () => {
  const isTheEditEnabled = useFt(constants.features.theEdit)
  const isTheShopEnabled = useFt(constants.features.theShop)
  const isCandleSubscriptionEnabled = useFt(constants.features.candleSubscription)
  const isGiftHubEnabled = useFt(constants.features.giftHub)
  const isHolidayMothersDayEnabled = useFt(constants.features.holidayMothersDay)
  const isHolidayFathersDayEnabled = useFt(constants.features.holidayFathersDay)
  const isScentHoroscopeEnabled = useFt(constants.features.scentHoroscope)
  const isShopSamplesEnabled = useFeatureIsOn(constants.features.shopSamples)
  const isTravelSizeEnabled = useFeatureIsOn(constants.features.travelSizeFragrances)
  const isDriftEcommercePromoEnabled = useFt(constants.features.driftEcommercePromo)
  const isCOARShopPageEnabled = useFeatureIsOn(constants.features.coarShopPage)
  const isDriftSubscriptionEnabled = useFt(constants.features.driftSubscription)
  const isGiftsUnder50PageEnabled = useFt(constants.features.giftsUnder50Page)
  const isBuy5pay4Enabled = useFeatureIsOn(constants.features.buy5pay4)
  const isFlowers1800CollaborationEnabled = useFeatureIsOn(constants.features.flowers1800Collaboration)
  const isWeddingCollaborationEnabled = useFeatureIsOn(constants.features.weddingCollaboration)
  const isMarvelCollaborationNavEnabled = useFeatureIsOn(constants.features.marvelCollaborationNav)
  const isMarvelCollaborationAltNavEnabled = useFeatureIsOn(constants.features.marvelCollaborationAltNav)
  const isOkCupidCollaborationEnabled = useFeatureIsOn(constants.features.okCupidCollaboration)
  const isGiftHubEvergreenEnabled = useFeatureIsOn(constants.features.giftHubEvergreen)
  const isTheSummerEditEnabled = useFeatureIsOn(constants.features.theSummerEdit)
  const isTheEditHolidayEnabled = useFeatureIsOn(constants.features.theEditHoliday)
  const isTheEditValentinesDayEnabled = useFeatureIsOn(constants.features.theEditValentinesDay)
  const isVipSubscriberSaleEnabled = useFeatureIsOn(constants.features.vipSubscriberSale)
  const isSamplesSubscriptionEnabled = useFeatureIsOn(constants.features.addonSamples)
  const isCandleSaleEnabled = useFeatureIsOn(constants.features.candleSale)
  const isMiniBottlesPageEnabled = useFeatureIsOn(constants.features.miniBottlesPage)
  const isTheShopRootPageEnabled = useFeatureIsOn(constants.features.theShopRootPage)
  const isDigitalFragranceAwardWinnersEnabled = useFeatureIsOn(constants.features.digitalFragranceAwardWinners)
  const isBlackFridaySaleEnabled = useFeatureIsOn(constants.features.blackFridaySale)
  const isFomArchivePageEnabled = useFeatureIsOn(constants.features.fomArchive)
  const isWorldOfFragrancePageEnabled = useFeatureIsOn(constants.features.worldOfFragrance)
  const isGiftSubscriptionEntrypointsEnabled = useFeatureIsOn(constants.features.giftSubscriptionEntrypoints)
  const isGrossePointeCollaborationEnabled = useFeatureIsOn(constants.features.grossePointeCollaboration)
  const isGiftCardsEntrypointsEnabled = useFeatureIsOn(constants.features.giftCardsEntrypoints)
  const isSelectCampaignPremiumNavEnabled = useFeatureIsOn(constants.features.selectCampaignPremiumNav)
  const isFragranceTrends2025NavEnabled = useFeatureIsOn(constants.features.fragranceTrends2025Nav)

  const { gender, hasScentProfile } = useUser()

  const strapiWSTONavigationData = useStrapiWSTONavigationData()
  const isStrapiWSTONavigationDataEnabled = Boolean(strapiWSTONavigationData)
  const isStrapiWSTONavigationPerfumesBannerExists = Boolean(strapiWSTONavigationData?.banners?.perfumes)
  const isStrapiWSTONavigationColognesBannerExists = Boolean(strapiWSTONavigationData?.banners?.colognes)
  const isStrapiWSTONavigationDiscoverBannerExists = Boolean(strapiWSTONavigationData?.banners?.discover)

  const { data: selectCampaignsData } = useSelectCampaigns()

  const { privateSale } = usePrivateSale()
  const isPrivateSaleEnabled = Boolean(privateSale)

  const { subscription } = useSubscription()
  const isSubscribed = subscription?.isSubscribed
  const isBuyQueueEnabled = subscription?.stateOptions?.canBuyQueue
  const canApplyTossInOffer = subscription?.canApplyTossInOffer

  const { caseSubscription } = useCaseSubscription()
  const isCaseSubscriptionEnabled = caseSubscription?.isEnabled
  const isCaseSubscriptionSelected = caseSubscription?.isSelected

  const { candleSubscription } = useCandleSubscription()
  const isCandleSubscriptionSelected = candleSubscription?.isSelected
  const isCandleSubscriptionVisible = Boolean(isCandleSubscriptionEnabled && candleSubscription?.isEnabled)

  const { driftSubscription } = useDriftSubscription()
  const isDriftSubscriptionSelected = driftSubscription?.isSelected
  const isDriftSubscriptionVisible = Boolean(isDriftSubscriptionEnabled && driftSubscription?.isEnabled)

  const { samplesSubscription } = useSamplesSubscription({ skip: !isSamplesSubscriptionEnabled })
  const isSamplesSubscriptionSelected = samplesSubscription?.isSelected
  const isSamplesSubscriptionVisible = Boolean(isSamplesSubscriptionEnabled && samplesSubscription?.isEnabled)

  const isPremiumNavTabVisible = Boolean(selectCampaignsData?.campaignsList?.length) && isSelectCampaignPremiumNavEnabled

  const { stats: { available } } = useReferralInvites()
  const isReferralItemVisible = Boolean(available)

  const isTopLevelCandleClubEntryPointVisible = !isSamplesSubscriptionVisible && isCandleSubscriptionVisible && !isCandleSubscriptionSelected
  const isTopLevelSamplesSubscriptionEntryPointVisible = isSamplesSubscriptionVisible && !isSamplesSubscriptionSelected

  let discoverSectionBanner = isStrapiWSTONavigationDiscoverBannerExists ? StrapiWSTONavigationBanner : StrapiNavigationBanner
  const perfumesSectionBanner = isStrapiWSTONavigationPerfumesBannerExists ? StrapiWSTONavigationBanner : StrapiNavigationBanner
  const colognesSectionBanner = isStrapiWSTONavigationColognesBannerExists ? StrapiWSTONavigationBanner : StrapiNavigationBanner

  const isUpgradeEntryPointsEnabled = subscription?.isUpgradableFromMonthlyPlan

  const limitedDropNavigationItems = useLimitedDropNavigationItems({ placement: 'header' })

  return useMemo(() => {
    let theEditTitle = messages.edit
    const theEditLabel = isHolidayMothersDayEnabled || isHolidayFathersDayEnabled
      ? messages.labels.new
      : (isBlackFridaySaleEnabled ? messages.labels.sale : undefined)

    if (isHolidayFathersDayEnabled) {
      theEditTitle = globalMessages.theFathersDayEdit
    }
    else if (isHolidayMothersDayEnabled) {
      theEditTitle = globalMessages.theMothersDayEdit
    }
    else if (isTheSummerEditEnabled) {
      theEditTitle = messages.theSummerEdit
    }
    else if (isTheEditHolidayEnabled) {
      theEditTitle = messages.theEditHoliday
    }
    else if (isTheEditValentinesDayEnabled) {
      theEditTitle = messages.theEditValentinesDay
    }

    const giftHubNavigationItemData = isGiftHubEnabled
      ? getGiftHubNavigationItemData({
        isHolidayMothersDayEnabled,
        isHolidayFathersDayEnabled,
        isGiftHubEvergreenEnabled,
      })
      : null

    const data: Navigation.HeaderDesktopSection[] = [
      {
        id: 'perfumes',
        title: messages.perfumes,
        list: [
          {
            id: 'perfumes-all',
            title: messages.allPerfumes,
            to: links.subscription.perfumes,
          },
          {
            id: 'perfumes-scentbird-select',
            title: messages.scentbirdSelect,
            to: links.scentbirdSelect,
          },
          {
            id: 'perfumes-new-arrivals',
            title: messages.newArrivals,
            to: links.newArrivals.perfumes,
          },
          {
            id: 'perfumes-bestsellers',
            title: messages.bestsellers,
            to: links.bestsellers.perfumes,
          },
          {
            id: 'perfumes-pom',
            title: messages.pom,
            to: links.subscription.currentPerfumeOfTheMonth,
          },
          {
            id: 'perfumes-clean',
            title: messages.clean,
            to: links.clean.perfumes,
          },
          {
            id: 'perfumes-exclusive',
            title: messages.exclusive,
            to: `${links.subscription.perfumes}?category=exclusive`,
          },
          {
            id: 'perfumes-collections',
            title: messages.collections,
            to: links.collectionsWomen,
          },
          {
            id: 'header-discover-allBrands',
            title: messages.allBrands,
            to: links.brands,
          },
        ].filter(Boolean),
        withColumns: true,
        bannerComponent: perfumesSectionBanner,
      },
      {
        id: 'colognes',
        title: messages.colognes,
        list: [
          {
            id: 'colognes-all',
            title: messages.allColognes,
            to: links.subscription.colognes,
          },
          {
            id: 'colognes-scentbird-select',
            title: messages.scentbirdSelect,
            to: links.scentbirdSelect,
          },
          {
            id: 'colognes-new-arrivals',
            title: messages.newArrivals,
            to: links.newArrivals.colognes,
          },
          {
            id: 'colognes-bestsellers',
            title: messages.bestsellers,
            to: links.bestsellers.colognes,
          },
          {
            id: 'colognes-com',
            title: messages.com,
            to: links.subscription.currentCologneOfTheMonth,
          },
          {
            id: 'colognes-clean',
            title: messages.clean,
            to: links.clean.colognes,
          },
          {
            id: 'colognes-exclusive',
            title: messages.exclusive,
            to: `${links.subscription.colognes}?category=exclusive`,
          },
          {
            id: 'colognes-collections',
            title: messages.collections,
            to: links.collectionsMen,
          },
          {
            id: 'header-discover-allBrands',
            title: messages.allBrands,
            to: links.brands,
          },
        ].filter(Boolean),
        withColumns: true,
        bannerComponent: colognesSectionBanner,
      },
      isPremiumNavTabVisible && {
        id: 'premium',
        title: messages.premium,
        to: links.scentbirdSelect,
        withColumns: true,
        bannerComponents: [
          StrapiNavigationBanner,
          StrapiNavigationBanner,
        ],
      },
      !isSelectCampaignPremiumNavEnabled && {
        id: 'beauty',
        title: messages.beauty,
        to: links.subscription.beauty,
      },
      isUpgradeEntryPointsEnabled && {
        id: 'header-upgrade',
        title: messages.upgrade2Scents,
        to: links.upgradeProduct,
      },
      !isUpgradeEntryPointsEnabled && isTopLevelCandleClubEntryPointVisible && {
        id: 'candleClub',
        title: messages.candleSubscription,
        to: links.candleSubscription,
      },
      !isUpgradeEntryPointsEnabled && isTopLevelSamplesSubscriptionEntryPointVisible && {
        id: 'samplesSubscription',
        title: messages.samplesSubscription,
        to: links.samplesSubscription,
      },
      {
        id: 'shop',
        title: messages.shop,
        withDivider: true,
        list: [
          isTheShopRootPageEnabled && {
            id: 'header-shop-root',
            title: messages.theShopRoot,
            to: links.shop.root,
            label: messages.labels.new,
          },
          ...(limitedDropNavigationItems.Shop || []),
          isShopSamplesEnabled && {
            id: 'header-shop-samples',
            title: messages.samples,
            to: links.shop.samples.root,
            label: isVipSubscriberSaleEnabled && messages.labels.sale,
          },
          {
            id: 'header-shop-scentbirdCases',
            title: messages.scentbirdCases,
            to: links.shop.scentbirdCases,
            label: (isVipSubscriberSaleEnabled || isBlackFridaySaleEnabled) && messages.labels.sale,
          },
          {
            id: 'header-shop-strawberry-shortcake',
            title: messages.strawberryShortcake,
            to: links.strawberryShortcake,
            label: messages.labels.new,
          },
          isTravelSizeEnabled && {
            id: 'header-shop-travelSizeFragrances',
            title: messages.travelSizeFragrances,
            to: gender === 'FEMALE' ? links.shop.travelSize.perfumes : links.shop.travelSize.colognes,
            label: isBuy5pay4Enabled || isVipSubscriberSaleEnabled ? messages.labels.sale : undefined,
          },
          isMiniBottlesPageEnabled && {
            id: 'header-shop-mini-bottles',
            title: messages.miniBottles,
            to: gender === 'FEMALE' ? links.shop.miniBottles.perfumes : links.shop.miniBottles.colognes,
            label: messages.labels.new,
          },
          isTheShopEnabled && {
            id: 'header-shop-fullSizeFragrances',
            title: messages.fullSizeFragrances,
            to: gender === 'FEMALE' ? links.shop.perfumes : links.shop.colognes,
            label: isBlackFridaySaleEnabled && messages.labels.sale,
          },
          isTheEditEnabled && {
            id: 'header-shop-edit',
            title: theEditTitle,
            to: links.shop.theEdit,
            label: theEditLabel,
          },
          isGiftsUnder50PageEnabled && {
            id: 'header-giftsUnder50',
            title: messages.giftsUnder50,
            to: links.giftsUnder50,
            label: isBlackFridaySaleEnabled && messages.labels.sale,
          },
          isGiftHubEnabled && {
            id: 'header-gift-hub',
            title: giftHubNavigationItemData.title,
            to: links.gifts,
          },
          isTheShopEnabled && {
            id: 'header-shop-candlesHomeScents',
            title: messages.candlesHomeScents,
            to: links.shop.candlesHomeScents,
            label: (isCandleSaleEnabled || isBlackFridaySaleEnabled) && messages.labels.sale,
          },
          (isCOARShopPageEnabled && !isBlackFridaySaleEnabled) && {
            id: 'header-shop-coar',
            title: messages.coarShopPage,
            to: links.shop.coar,
          },
          isDriftEcommercePromoEnabled && {
            id: 'header-drift-ecommerce-promo',
            title: messages.driftEcommercePromo,
            to: `${getLinkWithParams(links.brand, { name: 'drift' })}?productInputSource=THE_EDIT&sourceProductGroup=GiftSet`,
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        withColumns: true,
        bannerComponent: StrapiNavigationBanner,
      },
      {
        id: 'discover',
        title: messages.discover,
        list: [
          ...(limitedDropNavigationItems.Discover || []),
          {
            id: 'header-discover-scentProfile',
            title: messages.scentProfile,
            to: hasScentProfile ? links.scentProfile : links.smartRecommendations,
          },
          isScentHoroscopeEnabled && {
            id: 'header-discover-scentHoroscope',
            title: messages.scentHoroscope,
            to: links.scentHoroscope,
          },
          {
            id: 'header-discover-notesLibrary',
            title: messages.notesLibrary,
            to: links.notes,
          },
          isFomArchivePageEnabled && {
            id: 'header-discover-fomArchive',
            title: messages.fomArchive,
            to: links.subscription.perfumeOfTheMonthList,
          },
          {
            id: 'header-discover-fragranceFamilies',
            title: messages.fragranceFamilies,
            to: links.fragranceFamilies,
          },
          isStrapiWSTONavigationDataEnabled && {
            id: 'header-discover-featuredBrand',
            title: messages.featuredBrand,
            to: strapiWSTONavigationData.navLink,
          },
          isWeddingCollaborationEnabled && {
            id: 'header-discover-wedding',
            title: messages.weddingCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'wedding' }),
            label: messages.labels.new,
          },
          isFlowers1800CollaborationEnabled && {
            id: 'header-discover-flowers1800',
            title: messages.flowers1800Collaboration,
            to: getLinkWithParams(links.pr.landing, { slug: '1-800-flowers' }),
            label: messages.labels.new,
          },
          isDigitalFragranceAwardWinnersEnabled && {
            id: 'header-discover-digitalFragranceAwardWinners',
            title: messages.digitalFragranceAwardWinners,
            to: getLinkWithParams(gender === 'FEMALE' ? links.collectionWomen : links.collectionMen, { slug: 'scentbird-digital-fragrance-awards' }),
            label: messages.labels.new,
          },
          isMarvelCollaborationNavEnabled && {
            id: 'header-discover-marvel',
            title: isMarvelCollaborationAltNavEnabled ? messages.agathaAllAlongCollaboration : messages.marvelCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'marvel' }),
            label: messages.labels.new,
          },
          isOkCupidCollaborationEnabled && {
            id: 'header-discover-okcupid',
            title: messages.okCupidCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'sexy-scents-okcupid' }),
          },
          isGrossePointeCollaborationEnabled && {
            id: 'header-discover-grossepointe',
            title: messages.grossePointeCollaboration,
            to: getLinkWithParams(links.pr.landing, { slug: 'grosse-pointe-garden-society' }),
          },
          isWorldOfFragrancePageEnabled && {
            id: 'header-discover-worldOfFragrance',
            title: messages.myScentGuide,
            to: getLinkWithParams(links.promoHub.landing, { slug: 'world-of-fragrance' }),
            label: messages.labels.new,
          },
          isFragranceTrends2025NavEnabled && {
            id: 'header-discover-fragranceTrends2025',
            title: messages.fragranceTrends2025,
            to: getLinkWithParams(links.pr.landing, { slug: 'fragrance-trends' }),
            label: messages.labels.new,
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        withColumns: true,
        bannerComponent: discoverSectionBanner,
        onDisplay: () => {
          const context = getAnalyticsContext()

          if (context.subscriptionStatus !== 'LEAD') {
            trackOnce('Discover menu display')
          }
        },
      },
      isSubscribed && {
        id: 'member perks',
        title: messages.memberPerks,
        list: [
          isSamplesSubscriptionVisible && {
            id: 'header-member-perks-samplesSubscription',
            title: messages.samplesSubscription,
            to: links.samplesSubscription,
            label: messages.labels.new,
          },
          isCaseSubscriptionEnabled && {
            id: 'header-member-perks-caseSubscription',
            title: messages.caseSubscription,
            to: links.caseSubscription,
          },
          isDriftSubscriptionVisible && {
            id: 'header-member-perks-driftSubscription',
            title: messages.driftSubscription,
            to: links.driftSubscription,
            label: isDriftSubscriptionSelected ? messages.labels.subscribed : messages.labels.new,
          },
          isCandleSubscriptionVisible && {
            id: 'header-member-perks-candleSubscription',
            title: messages.candleSubscription,
            to: links.candleSubscription,
            label: isCandleSubscriptionSelected ? messages.labels.subscribed : messages.labels.new,
          },
          isBuyQueueEnabled && {
            id: 'header-member-perks-buyYourQueue',
            title: messages.buyYourQueue,
            to: links.buyQueue,
          },
          isPrivateSaleEnabled && {
            id: 'header-member-perks-privateSale',
            title: messages.privateSale,
            to: links.privateSale,
          },
          canApplyTossInOffer && {
            id: 'header-member-perks-lastMinuteAddons',
            title: messages.lastMinuteAddons,
            to: links.tossIn,
          },
          isReferralItemVisible && {
            id: 'referral-nav-item',
            to: links.invite,
            title: messages.referralProgram,
            content: (
              <ReferralNavItem
                spanClassName="text-white bg-gold-70 text-p4"
                freeFragranceClassName="text-gold-70"
              />
            ),
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        withColumns: false,
        bannerComponent: ({ onClick }) => {
          if (isSamplesSubscriptionVisible && !isSamplesSubscriptionSelected) {
            return (
              <SamplesSubscriptionBanner onClick={onClick} />
            )
          }

          if (isDriftSubscriptionVisible && !isDriftSubscriptionSelected) {
            return (
              <DriftSubscriptionNavBanner onClick={onClick} />
            )
          }

          if (isCandleSubscriptionVisible && !isCandleSubscriptionSelected) {
            return (
              <CandleSubscriptionBanner onClick={onClick} />
            )
          }

          if (isCaseSubscriptionEnabled && !isCaseSubscriptionSelected) {
            return (
              <CaseSubscriptionBanner onClick={onClick} />
            )
          }

          return <BuyQueueBanner onClick={onClick} />
        },
      },
      {
        id: 'gifts',
        title: isGiftHubEvergreenEnabled ? messages.giftsMenuEvergreen : messages.gifts,
        list: [
          isGiftHubEnabled && {
            id: 'header-gift-hub',
            title: giftHubNavigationItemData.title,
            to: links.gifts,
            exclusiveLabel: (
              <ExclusiveLabel
                labelText={messages.exclusives}
                variant={giftHubNavigationItemData.exclusiveLabelVariant}
              />
            ),
          },
          isGiftSubscriptionEntrypointsEnabled && {
            id: 'header-gifts-subscription',
            title: messages.giftSubscription,
            to: links.giftSubscription,
          },
          isGiftsUnder50PageEnabled && {
            id: 'header-gifts-giftsUnder50',
            title: messages.giftsUnder50,
            to: links.giftsUnder50,
          },
          isGiftCardsEntrypointsEnabled && {
            id: 'header-gifts-card',
            title: messages.giftCard,
            to: links.giftCard,
          },
          {
            id: 'header-gift-sets',
            title: messages.giftSets,
            to: links.giftSets.root,
          },
          !isGiftHubEnabled && {
            id: 'header-gifts',
            title: messages.allGifts,
            to: links.gifts,
          },
        ].filter(Boolean),
        subNavLinksPosition: 'right',
        background: giftHubNavigationItemData?.background,
        withColumns: false,
        bannerComponent: StrapiNavigationBanner,
      },
    ]

    return data.filter(Boolean)
  }, [
    gender,
    hasScentProfile,
    discoverSectionBanner,
    isGiftHubEnabled,
    isStrapiWSTONavigationDataEnabled,
    strapiWSTONavigationData?.navLink,
    isTheEditEnabled,
    isBuyQueueEnabled,
    canApplyTossInOffer,
    isCandleSubscriptionVisible,
    isCandleSubscriptionSelected,
    isCaseSubscriptionEnabled,
    isCaseSubscriptionSelected,
    isSubscribed,
    isTheShopEnabled,
    isPrivateSaleEnabled,
    isTopLevelCandleClubEntryPointVisible,
    isTopLevelSamplesSubscriptionEntryPointVisible,
    isScentHoroscopeEnabled,
    isHolidayMothersDayEnabled,
    isHolidayFathersDayEnabled,
    isShopSamplesEnabled,
    isReferralItemVisible,
    perfumesSectionBanner,
    colognesSectionBanner,
    isUpgradeEntryPointsEnabled,
    isTravelSizeEnabled,
    isDriftEcommercePromoEnabled,
    isCOARShopPageEnabled,
    isDriftSubscriptionVisible,
    isDriftSubscriptionSelected,
    isGiftsUnder50PageEnabled,
    isBuy5pay4Enabled,
    isFlowers1800CollaborationEnabled,
    isMarvelCollaborationNavEnabled,
    isMarvelCollaborationAltNavEnabled,
    isOkCupidCollaborationEnabled,
    isGiftHubEvergreenEnabled,
    isWeddingCollaborationEnabled,
    isTheSummerEditEnabled,
    isTheEditHolidayEnabled,
    isTheEditValentinesDayEnabled,
    isVipSubscriberSaleEnabled,
    isCandleSaleEnabled,
    isSamplesSubscriptionSelected,
    isSamplesSubscriptionVisible,
    isMiniBottlesPageEnabled,
    isTheShopRootPageEnabled,
    isDigitalFragranceAwardWinnersEnabled,
    isBlackFridaySaleEnabled,
    isFomArchivePageEnabled,
    limitedDropNavigationItems,
    isWorldOfFragrancePageEnabled,
    isGiftSubscriptionEntrypointsEnabled,
    isGrossePointeCollaborationEnabled,
    isGiftCardsEntrypointsEnabled,
    isPremiumNavTabVisible,
    isSelectCampaignPremiumNavEnabled,
    isFragranceTrends2025NavEnabled,
  ])
}


export default useDesktopNav
