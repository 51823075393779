import React from 'react'
import cx from 'classnames'
import { track } from 'analytics'
import { Message } from 'intl'

import { BackgroundImage, Image, Text, type TextColor } from 'components/dataDisplay'
import { Button, type ButtonProps } from 'components/inputs'
import { Href } from 'components/navigation'


export type BannerBaseProps = {
  logo?: string
  logoClassName?: string
  title?: string | Intl.Message
  titleColor?: TextColor
  titleClassName?: string
  text?: string | Intl.Message
  textColor?: TextColor
  buttonTitle?: string | Intl.Message
  buttonStyle?: Extract<ButtonProps['style'], 'primary' | 'gold-30'>
  bgImage: string
  to?: string
  toTab?: string
  href?: string
  content: string // for analytics event
  height?: number
  onClick?: () => void
  placement?: string
  trackParams?: Partial<Analytics.Events['Banner click']>
  caption?: string | Intl.Message
  sizes?: string
  isNavItem?: boolean
  'data-testid'?: string
}

const BannerBase: React.FunctionComponent<BannerBaseProps> = (props) => {
  const {
    'data-testid': dataTestId = 'banner',
    bgImage,
    buttonStyle,
    buttonTitle,
    content,
    logo,
    logoClassName,
    onClick,
    text,
    textColor,
    title,
    titleClassName,
    titleColor,
    to,
    toTab,
    href,
    placement = 'Desktop navigation menu',
    trackParams = {},
    caption,
    height = 296,
    sizes,
    isNavItem,
  } = props

  const handleClick = () => {
    track(isNavItem ? 'Desktop navigation menu click' : 'Banner click', {
      placement,
      content,
      link: to,
      action: 'link',
      ...trackParams,
    })

    if (typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <Href to={to} href={href} toTab={toTab} onClick={handleClick} data-testid={dataTestId}>
      <BackgroundImage
        className="bg-light-beige flex flex-col items-start justify-center p-32"
        imageClassName="object-left"
        src={bgImage}
        quality={85}
        sizes={sizes}
        style={{ height: `${height}rem` }}
      >
        {
          Boolean(logo) && (
            <Image className={logoClassName} src={logo} alt="" />
          )
        }
        {
          Boolean(title) && (
            <Text
              className={cx('whitespace-pre-line', titleClassName)}
              style="h7"
              color={titleColor}
              message={title}
              html
            />
          )
        }
        {
          Boolean(text) && (
            <Text
              className="mt-16 whitespace-pre-line"
              style="p3"
              color={textColor}
              message={text}
              html
            />
          )
        }
        {
          Boolean(buttonTitle) && (
            <Button
              className="mt-16"
              title={buttonTitle}
              size={48}
              style={buttonStyle}
              tabIndex={-1}
              width={240}
            />
          )
        }
        {
          Boolean(caption) && (
            <figcaption className="visually-hidden">
              <Message value={caption} />
            </figcaption>
          )
        }
      </BackgroundImage>
    </Href>
  )
}


export default BannerBase
